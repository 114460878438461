import { Reviews } from './reviews';

export class StarAverage {
  public average: number;
  public total: number;
  public reviews: Reviews[];

  public static fromJson(json: object): StarAverage {
    const review = new StarAverage();
    review.average = json['average'];
    review.total = json['total'];
    const bidon: Reviews[] = json['reviews'];
    if (bidon)
      review.reviews = bidon.map((jsonItem) => Reviews.fromJson(jsonItem));
    return review;
  }
}
