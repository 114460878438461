import { dateToUtc } from '../../helpers/utcDate';
import { UserSetting } from './setting';

export class User {
  public id: string;
  public firstName: string;
  public lastName: string;
  public token: string;
  public companyName: string;
  public phone: string;
  public email: string;
  public password: string;
  public profilImage: string;
  public companyNameInvoice: number;
  public twoFaType: number;
  public setting: UserSetting;
  public creation: Date;
  public active: number;
  public lastAccess: Date;
  public facebook: string;
  public website: string;

  public static fromJson(json: object): User {
    const user = new User();
    Object.assign(user, json);
    user.setting = UserSetting.fromJson(json);
    user.creation = dateToUtc(new Date(json['creation']));
    user.lastAccess = dateToUtc(new Date(json['lastAccess']));
    return user;
  }
}
