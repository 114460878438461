import { Injectable, ErrorHandler, PLATFORM_ID, inject } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import { browserTracingIntegration } from '@sentry/angular-ivy';
import { isPlatformBrowser } from '@angular/common';
//import { LogService } from '../service/logs.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    if (environment.production) {
      Sentry.init({
        dsn: 'https://cb32d2637879cfbc2e3efc7c3e7afe27@o203305.ingest.sentry.io/4506180454907904',
        maxValueLength: 100000,
        beforeSend(event) {
          // Modify the event here
          try {
            const platformId = inject(PLATFORM_ID);
            const isBrowser = isPlatformBrowser(platformId);

            if (isBrowser) {
              const lastFrame =
                event['exception']['values'][0]['stacktrace']['frames'][
                  event['exception']['values'][0]['stacktrace']['frames']
                    .length - 1
                ];

              if (lastFrame['filename'].includes('/ng-cli-ws')) {
                return null;
              }

              console.log(
                '************' +
                  event['exception']['values'][0]['value'] +
                  '************'
              );
              console.log(
                '************' +
                  event['exception']['values'][0]['stacktrace']['frames'][
                    event['exception']['values'][0]['stacktrace']['frames']
                      .length - 1
                  ]['filename'] +
                  '************'
              );
            } else {
              //const logservice = inject(LogService)
              //logservice.log(event["exception"]["values"][0]["value"])
            }
            return event;
          } catch {
            return event;
          }
        },
        integrations: [browserTracingIntegration()],
        tracePropagationTargets: [/https:\/\/lokatix\.net\/api/],
        tracesSampleRate: environment.tracesSampleRate, // We recommend adjusting this in production
        replaysSessionSampleRate: environment.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}
