export const environment = {
  apiUrl: 'https://beta.lokatix.net',
  production: true,
  cookieDomain: 'beta.lokatix.net',
  googleAnalytics: 'G-5PTGTMBS81',
  tracing: true,
  stripeKey:
    'pk_test_51N5uenLemcGBQjMPOuFDyJbLBB0KNaOX3O7Ey9THHkIjvxWKNaY6vZMAOqxEAy7YUbUfB8jLedfGh0k666jYgEad00iyeNr2IB',
  mapbox:
    'pk.eyJ1IjoiZnJlZGVyaWNjb3V0dSIsImEiOiJjbGRhM2phcHgwNzVlM3ZtdXN6OGR6cmZvIn0.Zvg_uzSAF5Zfd653JpnQ4g',
  places:
    'yYDMPrF9fTTD2GckslG5PyBSA2L6PbXGHpYnKKMyzA37OVBs1l9ner6wMNK1rF3TmhbYgZKq52T2UDulCse0KV41qRGv28m9lyW1nz8z7uz9TvF3Qk85v4emyoNeYvwm',
  hideGoogleIndex: true,
  urlImage: 'https://beta.lokatix.net',
  jwtName: 'jwtBeta',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  stripeId1: 'price_1ODtB3LemcGBQjMPg18aDLa7',
  stripeId2: 'price_1ODtDWLemcGBQjMPNiAg0nsO',
  stripeId3: 'price_1OiNfFLemcGBQjMPEslIai79',
  stripeId4: 'price_1OiNflLemcGBQjMPJzZ5a6K6',
  stripeId5: 'price_1OiNeYLemcGBQjMPy8pXc64j',
  stripeId100: 'price_1OiNdQLemcGBQjMP7mKwURsz',
  stripeId101: 'price_1OiNcaLemcGBQjMPCBy6STEe',
  stripeId102: 'price_1ODtLiLemcGBQjMPrExd4y1V'
};
