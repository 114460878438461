import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../dto/user/user';
import { TwoFa } from '../dto/twoFa/twoFa';
import { UserProfil } from '../dto/user/userProfil';
import { UserSetting } from '../dto/user/setting';
import { StarAverage } from '../dto/ads/starAverage';
import { UserProfilAgent } from '../dto/user/userProfilAgent';
import { UserReturnLogin } from '../dto/user/userReturnLogin';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  register(user: User): Observable<string> {
    return this.http
      .post('/api/User/Create', user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  save(user: UserProfil): Observable<string> {
    return this.http
      .post('/api/User/Save', user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  saveUserReport(user: User): Observable<string> {
    return this.http
      .post('/api/User/UserReportSave', user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  saveImageProfil(fileToUpload: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('Name', fileToUpload.name);
    formData.append('Image', fileToUpload);
    return this.http
      .post('/api/User/SaveImageProfil', formData, { responseType: 'text' })
      .pipe((data) => data);
  }

  public deleteImageProfil(): Observable<object> {
    return this.http.delete('/api/User/profilimage/delete');
  }

  getImageProfil(imageName: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('name', imageName);
    return this.http
      .get('/api/User/GetImageProfil', { params: params, responseType: 'blob' })
      .pipe((val) => val);
  }

  get(): Observable<object> {
    return this.http.get('/api/User/Get').pipe((data) => data);
  }

  delete(): Observable<object> {
    return this.http.delete('/api/User/Delete').pipe((val) => val);
  }

  getAll(filter: object): Observable<User[]> {
    return this.http
      .post('/api/User/GetAllUser', filter, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe(
        map((jsonArray: User[]) =>
          jsonArray.map((jsonItem) => User.fromJson(jsonItem))
        )
      );
  }

  login(login: string, password: string): Observable<object> {
    return this.http
      .post('/api/User/Login', { email: login, password: password })
      .pipe((data) => {
        return data;
      });
  }

  recoverPassword(email: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http
      .get('/api/User/RecoverPassword', {
        params: params,
        responseType: 'text'
      })
      .pipe((data) => data);
  }


  changePassword(password: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('newpassword', password);
    return this.http
      .get('/api/User/ChangePassword', { params: params })
      .pipe((data) => data);
  }

  resetPassword(password: string, emailUser: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('newpassword', password);
    params = params.append('emailUser', emailUser);
    return this.http
      .get('/api/User/ResetPassword', { params: params })
      .pipe((data) => data);
  }

  validCode(email: string, code: string): Observable<UserReturnLogin> {
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('code', code);
    return this.http
      .get<UserReturnLogin>('/api/User/ValidCode', {
        params: params,
      })
      .pipe(map((jsonItem) => UserReturnLogin.fromJson(jsonItem)));
  }

  validCodeReset(email: string, code: string): Observable<UserReturnLogin> {
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('code', code);
    return this.http
      .get<UserReturnLogin>('/api/User/ValidCode/Reset', {
        params: params,
      })
      .pipe(map((jsonItem) => UserReturnLogin.fromJson(jsonItem)));
  }

  setLanguage(lang: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('culture', lang);
    return this.http
      .get('/api/User/SetLanguage', { params: params })
      .pipe((val) => val);
  }

  validTwoFA(code: string): Observable<number> {
    let params = new HttpParams();
    params = params.append('code', code);
    return this.http
      .get('/api/User/ValidTwoFA', { params: params })
      .pipe(map((data) => +data));
  }

  validTwoFAAlwaysAsk(code: string): Observable<number> {
    let params = new HttpParams();
    params = params.append('code', code);
    return this.http
      .get('/api/User/ValidTwoFAAlways', { params: params })
      .pipe(map((data) => +data));
  }

  resendToken2FA(): Observable<number> {
    return this.http.get('/api/User/ResendToken2FA').pipe(map((data) => +data));
  }

  resendToken2FALogin(): Observable<number> {
    return this.http
      .get('/api/User/ResendToken2FALogin')
      .pipe(map((data) => +data));
  }

  getProfilAgent(id: string): Observable<UserProfilAgent> {
    return this.http
      .get('/api/User/Profil/Agent/' + id)
      .pipe(map((jsonItem) => UserProfilAgent.fromJson(jsonItem)));
  }

  getProfil(): Observable<UserProfil> {
    return this.http.get<UserProfil>('/api/User/Profil/');
  }

  GetPostingProfil(id: string): Observable<UserProfil> {
    return this.http
      .get('/api/User/Posting/' + id)
      .pipe(map((jsonItem) => UserProfil.fromJson(jsonItem)));
  }

  GetSetting(): Observable<UserSetting> {
    return this.http
      .get('/api/User/Setting')
      .pipe(map((jsonItem) => UserSetting.fromJson(jsonItem)));
  }

  SaveSetting(setting: UserSetting): Observable<UserSetting> {
    return this.http
      .post('/api/User/Setting/Save', setting, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe(map((jsonItem) => UserSetting.fromJson(jsonItem)));
  }

  setCurrency(currency: number): Observable<object> {
    return this.http
      .patch('/api/User/Currency/' + currency, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe((val) => val);
  }

  getCurrency(): Observable<object> {
    return this.http.get('/api/User/Currency').pipe(val => val);
  }

  getTwoFaInfo(idUser: string): Observable<TwoFa> {
    let params = new HttpParams();
    params = params.append('idUser', idUser);
    return this.http
      .get('/api/User/GetTwoFaInfo', { params: params })
      .pipe(map((jsonItem) => TwoFa.fromJson(jsonItem)));
  }

  setTwoFa(idUser: string, type: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('type', type);
    params = params.append('idUser', idUser);
    return this.http
      .get('/api/User/SetTwoFa', { params: params })
      .pipe(map((data) => data));
  }

  activateTwoFaGa(idUser: string, code: string): Observable<number> {
    let params = new HttpParams();
    params = params.append('idUser', idUser);
    params = params.append('code', code);
    return this.http
      .get('/api/User/ActivateTwoFaGa', { params: params })
      .pipe(map((data) => +data));
  }

  sendToken2FAlways(): Observable<any> {
    return this.http
      .get('/api/User/SendToken2FAlways')
      .pipe(map((data) => +data));
  }

  sendCode(email: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http
      .get('/api/User/SendCode', {
        params: params,
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  updateStarReview(id: string, star: number): Observable<StarAverage> {
    return this.http
      .post(
        '/api/User/Review/Update/' + id,
        { star: star },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      )
      .pipe(map((jsonItem) => StarAverage.fromJson(jsonItem)));
  }

  deleteReviews(id: string): Observable<StarAverage> {
    return this.http
      .delete('/api/User/Review/Delete/' + id)
      .pipe(map((jsonItem) => StarAverage.fromJson(jsonItem)));
  }

  refresh(): Observable<object> {
    return this.http.get('/api/User/Refresh').pipe((data) => data);
  }

  paymentSucess(sessionId: string): Observable<object> {
    return this.http
      .get('/api/User/PaymentSucess/' + sessionId)
      .pipe((data) => data);
  }
}
